@import "../../sass/colors";

nav.left-nav {
    background-color: $blue;
    color: white;
    z-index: 100;
    position: fixed;
}

nav.left-nav .navbar-burger {
    color: white;
}

nav.left-nav .navbar-burger span {
    display: block;
}

nav.left-nav div.has-dropright .dropright-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

nav.left-nav div.has-dropright .dropright .nav-section .nav-section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid $mid-turquoise;
    padding: 1rem 0 0 0;
}

nav.left-nav div.has-dropright .dropright .nav-section h1 {
    color: $blue;
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-left: 0.5rem;
    font-weight: bold;
}

nav.left-nav div.has-dropright .dropright .dropright-item {
    display: flex;
    border-bottom-color: $mid-turquoise;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding: 0.5rem 0;
}

@media screen and (min-width: 901px) {

    nav.left-nav .navbar-burger {
        display: none;
    }

    nav.left-nav {
        width: 5rem;
        left: 0;
        transition: width 0.2s;
        height: 100%;
        display: flex;
        align-items: flex-start;

        > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        > div:first-child {
            margin-left: 0.5rem;
        }
    }
    nav.left-nav.is-open,
    nav.left-nav:hover {
        width: 12rem;
    }

    nav.left-nav .logo {
        display: block;
        margin: 2rem 0;
    }

    nav.left-nav .navbar-menu {
        padding-left: 1rem;
        width: 100%;
        background-color: inherit;
        box-shadow: inherit;
        flex-grow: 1;
    }

    nav.left-nav,
    nav.left-nav .navbar-menu,
    nav.left-nav .navbar-menu .navbar-start,
    nav.left-nav .navbar-menu .navbar-end {
        display: flex;
        flex-direction: column;
    }

    nav.left-nav .navbar-menu .has-dropright {
        margin: 1rem 0;
    }

    nav.left-nav .navbar-menu {
        justify-content: space-between;
    }

    nav.left-nav div span {
        display: none;
    }
    nav.left-nav .navbar-menu.is-active div span,
    nav.left-nav:hover div span {
        display: block;
        flex-grow: 1;
    }

    nav.left-nav div.has-dropright {
        width: 100%;
    }
    nav.left-nav:hover div.has-dropright:hover {
        width: 100%;
        border-right-width: 6px;
        border-right-style: solid;
        border-right-color: $orange;
    }
    nav.left-nav div.has-dropright .dropright-header {
        cursor: pointer;
    }

    nav.left-nav .navbar-start,
    nav.left-nav .navbar-start div,
    nav.left-nav .navbar-end,
    nav.left-nav .navbar-end div {
        width: 100%;
    }

    nav.left-nav div.has-dropright .dropright {
        position: fixed;
        width: 25rem;
        height: 100%;
        left: 12rem;
        top: 0;
        background-color: white;
        border-right: 1px solid grey;
        padding: 5rem 2rem;
        overflow: auto;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.3s, opacity 0.3s;
    }
    nav.left-nav:hover div.has-dropright:hover .dropright {
        visibility: visible;
        opacity: 1;
    }

    nav.left-nav div.has-dropright .dropright .nav-section {
        color: $turquoise;
    }
}

@media screen and (max-width: 900px) {

    nav.left-nav .navbar-menu.is-active .dropright-header {
        padding: 1rem 0;
    }

    nav.left-nav .navbar-menu.is-active .dropright-header span {
        font-size: 1.7rem;
    }

    @media screen and (orientation: portrait) {
        nav.left-nav {
            height: 5rem;
            width: 100%;
            top: 0;

            > div {
                display: flex;
                flex-direction: row;
                height: 100%;
                width: 100%;
                padding: 0 1rem;
                align-items: center;
            }
        }

        nav.left-nav .navbar-menu {
            display: none;
        }
        nav.left-nav .navbar-menu.is-active {
            z-index: 10;
            display: block;
            width: 100%;
            height: calc(100vh - 7rem);
            position: absolute;
            background-color: $blue;
            overflow: auto;
        }

        nav.left-nav .navbar-menu.is-active .has-dropright {
            flex-direction: column;
        }

        nav.left-nav .navbar-menu.is-active .dropright {
            display: block;
            background-color: white;
            padding: 2rem;
        }
    }

    @media screen and (orientation: landscape) {
        nav.left-nav {
            width: 5rem;
            height: 100%;
            left: 0;

            > div {
                display: flex;
                flex-direction: column-reverse;
                height: 100%;
                width: 100%;
                padding: 1rem;
                justify-content: space-between;
                align-items: center;
            }
        }
        nav.left-nav .navbar-menu {
            display: none;
        }
        nav.left-nav .navbar-menu.is-active {
            z-index: 10;
            display: block;
            width: calc(100vw - 5rem);
            height: 100%;
            position: absolute;
            top: 0;
            left: 5rem;
            background-color: $blue;
            overflow: auto;
        }

        nav.left-nav .navbar-menu.is-active .dropright {
            display: block;
            background-color: white;
            padding: 2rem;
        }
    }
}