.react-datepicker-wrapper {
    width: 21rem;
}
.react-datepicker-wrapper > .react-datepicker__input-container > input {
    width: 21rem;
    max-width: 100%;
    height: 2.5em;
    border-radius: 4px;
    font-size: 1rem;
    box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
    background-color: white;
    color: #363636;
    border: 1px solid #dbdbdb;
    display: inline-flex;
    justify-content: flex-start;
    line-height: 1.5;
    padding: calc(0.5em - 1px) calc(0.75em - 1px);
    position: relative;
    vertical-align: top;
}

.date-range-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.content .date-range-container p,
.content .date-range-container p:not(:last-child),
.date-range-container p {
    margin: 0;
}