$blue: #003F61;
$turquoise: #157EB8;
$yellow: #F1DC1E;
$red: #F11E1E;
$orange: #F2B400;
$green: #07CC2E;
$white-bis: #F4F6FB;
$greyed: #ececec;
$pale-turquoise: #e4f5ff;
$mid-turquoise: #bee8ff;
$lilac: #beaed4;