@import "./sass/colors";

#bg {
  width: 100vw;
  height: 100vh;
  background-color: $white-bis;
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  z-index: -1;
}

@media screen and (max-width: 900px) {
  @media screen and (orientation: portrait){
    #main-container {
      padding-bottom: 2rem;
    }
    #main-content-wrapper {
      margin-top: 5rem;
    }
  }

  @media screen and (orientation: landscape){
    #main-container {
      padding-right: 2rem;
    }
    #main-content-wrapper {
      margin-left: 5rem;
    }
  }
}
@media screen and (min-width: 901px) {
  #main-content-wrapper {
    margin-left: 5rem;
  }
}