label.checkbox {
    display: flex;
    align-items: center;
}
.circle {
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    display: inline-block;
    margin: 0 0.2rem 0 0;
}
.circle.active {
    background-color: green;
}
.circle.inactive {
    background-color: grey;
}

.station-info {
    display: flex;
    align-items: center;
    width: 100%;
}
.station-info > span:nth-child(2) {
    width: 2.25rem;
    flex: 0 0 2.25rem;
}
.station-info > span:nth-child(4) {
    flex: 1 1 auto;
}