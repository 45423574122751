@import "../sass/colors";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.naked-obs-margined {
  margin-top: 1rem;
}

input[type=checkbox] {
  margin-right: 0.5rem;
}
input.input,
textarea.textarea {
  background-color: white;
}

/* no arrows on number inputs, Chrome-like and Firefox */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

.content {
  position: relative;
  padding: 1rem 3rem;
}

.content.is-centered {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
}

.svg-inline--fa {
  margin: 0.5rem;
}

label {
  margin-top: 1.5rem;
}
.label:not(:last-child) {
  margin-bottom: 1rem;
}
label.radio {
  margin-top: 0.25rem;
}

.field label {
  margin: inherit;
}

.field.has-addons {
  align-items: center;
}

a.link {
  color: #222;
  text-decoration: underline dotted;
}

button.button {
  border-radius: 1.5rem;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

button.button.is-narrow {
  width: auto;
  min-width: 5rem;
}

.justifies-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.justifies-right > * {
  width: fit-content;
}

.navbar button {
  width: auto;
}

h1.title.is-1 {
  color: $blue;
  background-color: white;
  border-radius: 1rem;
  border: 1px solid $greyed;
  padding: 1rem;
  font-size: 2.4rem;
}

h2.title.is-2 {
  color: $blue;
  padding: 1rem;
  font-size: 2.1rem;
}

h3.title.is-3 {
  color:  $blue;
  padding: 1rem;
  font-size: 1.8rem;
}

.box {
  border-radius: 1rem;
}

table.table th,
.content table thead th,
table.table th:not([align]),
.content table thead th:not([align]) {
  background-color: $blue;
  color: white;
  vertical-align: middle;
  text-align: center;
}

.table.is-striped tbody tr:not(.is-selected):nth-child(odd) {
  background-color: $greyed;
}

.table.is-striped tbody tr:not(.is-selected):nth-child(even) {
  background-color: $pale-turquoise;
}

.content table td, .content table th {
  border: 1px solid white;
}

.date-picker-container button.button {
  width: auto;
}

.box h2.title.is-2 {
  font-size: 2rem;
}

.box .box-header {
  margin-bottom: 1rem;
}

.box .box-header h2 {
  margin: 0;
}

@media screen and (max-width: 1023px) {
  .box .box-header {
    flex-direction: column;
  }
}

@media screen and (max-width: 650px) {
  .content {
    padding: 1rem;
  }
  h1.title.is-1 {
    font-size: 1.8rem;
  }
  h2.title.is-2,
  .box .box-header h2,
  .box h2.title.is-2 {
    font-size: 1.4rem;
  }
  .box .box-header h2 {
    text-align: center;
  }
}

thead > tr.is-sticky {
  z-index: 20;
}

tr.is-sticky {
  position: relative;
  z-index: 10;
}

tr.is-sticky > th,
tr.is-sticky > td {
  position: sticky;
  top: 0;
}

.square {
  height: 1rem;
  width: 1rem;
  margin-right: 1rem;
  display: inline-block;
  flex: 0 0 1rem;
}

.circle {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  display: inline-block;
  flex: 0 0 1rem;
}

.is-vcentered {
  display: flex;
  align-items: center;
}

.columns.is-compact .column {
  padding-top: 0;
  padding-bottom: 0;
}

@media screen and (max-width: 1023px) {
  .columns.is-multiline.is-striped > *:nth-child(even) {
    background-color: lightgray;
  }
}
@media screen and (min-width: 1024px) {
  .columns.is-multiline.is-striped > *:nth-child(4n-1),
  .columns.is-multiline.is-striped > *:nth-child(4n) {
    background-color: lightgray;
  }
}