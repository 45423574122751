@import "../sass/colors";

.map-container {
    height: 600px;
    position: relative;
    background-color: white;
    border: 1px solid grey;
    border-radius: 1rem;
    overflow: hidden;
}
.map-container.full-height {
    height: calc(100vh - 4rem);
}
.map-container.full-height.legended {
    height: calc(100vh - 11.125rem);
}

.date-p {
    margin-top: -1rem;
    color: $blue;
}

.columns.no-bottom-margin {
    margin-bottom: 0;
}

.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 100;
    position: absolute;
    top: 100px;
    left: 0;
    margin: 12px;
    border-radius: 4px;
}

.map-legend {
    background: rgba(256, 256, 256, 0.8);
    padding: 6px 12px;
    z-index: 100;
    position: absolute;
    margin: 12px;
    border-radius: 4px;
}

.map-legend .legend-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.map-legend .legend-item .square {
    border: 1px solid grey;
}




