nav.social {
    width: 100%;
    position: relative;
    height: 4rem;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem;
}
nav.social > div {
    display: flex;
    flex-direction: row;
}

nav.social div.logged {
    display: flex;
    align-items: center;

    > div:first-child {
        display: flex;
        align-items: center;
    }
}

nav.social .logged .dropdown {
    display: none;
    background-color: white;
}
nav.social .logged:hover .dropdown,
nav.social .logged.active .dropdown {
    display: block;
    position: absolute;
    right: 1rem;
    top: 3.5rem;
    border-radius: 3px;
    box-shadow: grey 0 0 3px;
}

nav.social .dropdown .dropdown-item,
nav.social .dropdown button.dropdown-item {
    padding: 0.25rem 0.5rem;
    min-width: 15rem;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    margin: 0;
}
nav.social .dropdown .dropdown-item span,
nav.social .dropdown button.dropdown-item span {
    margin-left: 0.25rem;
}

nav.social .dropdown .dropdown-item:hover,
nav.social .dropdown button.dropdown-item:hover {
    background-color: #F2B400;
}

nav.social .dropdown .dropdown-item:not(:first-child) {
    border-top: 1px solid grey;
}